import React from "react";
import "../Navbar/Navbar.css";
const Navbar = () => {
  const handleLinkClick = () => {
    const checkbox = document.getElementById("menu-toggle-checkbox");

    if (checkbox.checked) {
      checkbox.checked = false;
    }
  };
  return (
    <>
      <header>
        <input type="checkbox" name="menu-toggle" id="menu-toggle-checkbox" />
        <label className="menu-toggle" htmlFor="menu-toggle-checkbox">
          <span className="menu-toggle_line" />
          <span className="menu-toggle_line" />
          <span className="menu-toggle_line" />
        </label>
        <a href="#h" className="branding">
          MVP
        </a>
        <nav>
          <ul>
            <li className="menu-item" data-menu-index={1}>
              <a href="#h" className="meu-item_link" onClick={handleLinkClick}>
                Home
              </a>
            </li>
            <li className="menu-item" data-menu-index={2}>
              <a href="#ip" className="meu-item_link" onClick={handleLinkClick}>
                Creating IP
              </a>
            </li>
            <li className="menu-item" data-menu-index={3}>
              <a
                href="#crh"
                className="meu-item_link"
                onClick={handleLinkClick}
              >
                Commercial Rights
              </a>
            </li>
            <li className="menu-item" data-menu-index={4}>
              <a href="#ss" className="meu-item_link" onClick={handleLinkClick}>
                Sponsorships
              </a>
            </li>
            <li className="menu-item" data-menu-index={5}>
              <a
                href="#pms"
                className="meu-item_link"
                onClick={handleLinkClick}
              >
                Player's Management
              </a>
            </li>
            <li className="menu-item" data-menu-index={6}>
              <a
                href="#gallery"
                className="meu-item_link"
                onClick={handleLinkClick}
              >
                Gallery
              </a>
            </li>
            <li className="menu-item" data-menu-index={7}>
              <a
                href="#news"
                className="meu-item_link"
                onClick={handleLinkClick}
              >
                News
              </a>
            </li>
            <li className="menu-item" data-menu-index={8}>
              <a href="#cu" className="meu-item_link" onClick={handleLinkClick}>
                Contact Us
              </a>
            </li>
          </ul>
        </nav>
      </header>
    </>
  );
};

export default Navbar;
