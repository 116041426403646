import React from 'react'
import '../Footer/Footer.css'
import { FaFacebook, FaSquareXTwitter } from "react-icons/fa6";
import { FaInstagramSquare } from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";
const Footer = () => {
  return (
    <>
        <section className="footer-section">
     <footer className="footer">
  <div className="footer__container container-f grid">
    <div className="footer__content grid">
      <a href="#h" className="footer__logo">MVP</a>
      <ul className="footer__links">
        <li>
          <a href="#ip" className="footer__link">About Me</a>
        </li>
        <li>
          <a href="#news" className="footer__link">News</a>
        </li>
        <li>
          <a href="#gallery" className="footer__link">Gallery</a>
        </li>
        <li>
          <a href="#cu" className="footer__link">Contact Me</a>
        </li>
      </ul>
      {/*-
      <div className="footer__social">
        <a href="https://www.facebook.com/" target="_blank" className="footer__social-link">
        <FaFacebook className="icon-c"/>
        </a>
        <a href="https://www.instagram.com/" target="_blank" className="footer__social-link">
        <FaInstagramSquare className="icon-c" />
        </a>
        <a href="https://twitter.com/" target="_blank" className="footer__social-link">
        <FaSquareXTwitter className="icon-c" />
        </a>
        <a href="https://api.whatsapp.com/send/?phone=%2B91000000000&text&type=phone_number&app_absent=0" target="_blank" className="footer__social-link">
        <IoLogoWhatsapp className="icon-c" />
        </a>
      </div>
      -*/}
    </div>
    <div className="copyright">
    <span className="footer__copy">
    MVP 2024. All rights reserved.&copy;|Designed by<a href="https://infogainsoft.com" className='lim' target="_blank"> Infogainsoft</a>
    </span>
    
    </div>
  </div>
</footer>

      </section>
    </>
  )
}

export default Footer