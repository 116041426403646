import React, { useState } from "react";
import "../Home/Home.css";
import Navbar from "../Navbar/Navbar";
import emailjs from 'emailjs-com';
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";


const Home = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
    message: '',
  });
  const [errors, setErrors] = useState({
    name: '',
    email: '',
    mobile: '',
    message: '',
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  
    // Validation logic
    switch (name) {
      case 'name':
        setErrors({
          ...errors,
          name: /^[A-Za-z\s]+$/.test(value) ? '' : 'Name must contain only alphabets',
        });
        break;
      case 'email':
        setErrors({
          ...errors,
          email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) ? '' : 'Invalid email format.',
        });
        break;
      case 'mobile':
        setErrors({
          ...errors,
          mobile: /^\d{10}$/.test(value) ? '' : 'Mobile number must be 10 digits.',
        });
        break;
      case 'message':
        setErrors({
          ...errors,
          message: value.length >= 10 ? '' : 'Message must be at least 10 characters long.',
        });
        break;
      default:
        break;
    }
  };
  

  const handleSubmit = (e) => {
    e.preventDefault();
    
    emailjs.sendForm(
      'service_7utsuxj', 
      'template_hailfht', 
      e.target,
      'VcpTLNdFDfCZ3XYgi' 
    )
    .then((result) => {
      console.log(result.text);
      alert('Message Sent!');
    }, (error) => {
      console.log(error.text);
      alert('An error occurred, please try again.');
    });
  };
  const contentArray = [
    {
      heading:
        "Introducing MVP Quest Pvt Ltd, where the passion for sports meets unparalleled management expertise. As a dynamic sports management company, MVP Quest specializes in crafting and nurturing sports leagues from conception to execution. Our comprehensive suite of services includes acquiring commercial rights, meticulous player management, and expertly orchestrating sponsorship sales to ensure the success and longevity of every sporting endeavor we undertake.",
      paragraph: "XYZ — Owner",
      imageUrl: "t1.png",
    },
    {
      heading:
        "At MVP Quest Pvt Ltd, we understand that building a thriving sports league requires more than just organizing matches; it demands a strategic approach that maximizes commercial opportunities while prioritizing the welfare and development of athletes. With years of industry experience and a dedicated team of professionals, we are committed to delivering exceptional results for both our clients and the sporting community at large.",
      paragraph: "XYZ — Owner",
      imageUrl: "t2.png",
    },
    {
      heading:
        "Whether it’s conceptualizing innovative league formats, negotiating lucrative sponsorship deals, or providing personalized support to athletes, MVP Quest Pvt Ltd is your trusted partner every step of the way. Our unwavering dedication to excellence and our relentless pursuit of sporting greatness set us apart as leaders in the field of sports management.",
      paragraph: "XYZ — Owner",
      imageUrl: "t3.png",
    },
  ];
 
  const handleLeftButtonClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? contentArray.length - 1 : prevIndex - 1
    );
  };

  const handleRightButtonClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === contentArray.length - 1 ? 0 : prevIndex + 1
    );
  };
  return (
    <>
      <section id="h" className="home-video-section">
        <div className="video-section">
          <div className="overlay-content-2">
            <Navbar />
          </div>
          <video autoPlay loop muted className="video-container">
            <source src="video.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div className="overlay"></div>
          <div className="overlay-content">
            <h1 className="overlay-text">Welcome to MVP Quest</h1>
          </div>

          <div className="overlay-content-4">
          {/*-
            <button className="overlay-content-4-button">Know more</button>
            -*/}
          </div>
        </div>
      </section>
      <section id="ip" className="ip-section">
        <div className="ip-div">
          <h1 className="ip-text" data-aos="fade-down">
            "At MVP Quest Pvt Ltd, we understand the power of a unique and
            compelling sporting concept. We specialize in conceptualizing and
            developing intellectual properties that resonate with audiences and
            captivate the imagination of fans worldwide. "
          </h1>
          <div className="ip-text-div">
            <h4 className="ip-subtext" data-aos="fade-down">
              Whether it’s a groundbreaking league format, an innovative
              tournament structure, or a captivating sporting event, MVP Quest
              Pvt Ltd has the expertise and creativity to bring your vision to
              life. Our team of experienced professionals works tirelessly to
              craft unforgettable sporting experiences that leave a lasting
              impression on fans and sponsors alike.
            </h4>
            {/*-
            <button className="ip-button" data-aos="flip-up">
              Know more <FaArrowRight />
            </button>   -*/}
          </div>
        </div>
        <div className="image-contactus">
          <div className="contact-us-container">
          <div className="contact-right"></div>
            <div className="contact-left">
              <div className="content"></div>
              <div className="image-overlay"></div>
            </div>
            
          </div>
        </div>
      </section>
      <section id="crh" className="crh-section">
        <div className="crh-para-div">
          <h1 className="crh-text" data-aos="fade-down">
            As the commercial rights holder for numerous sporting properties,
            MVP Quest Pvt Ltd is entrusted with maximizing the commercial value
            of these assets.
          </h1>
          <div className="crh-text-div">
            <h4 className="crh-subtext" data-aos="fade-down">
              Leveraging our extensive network of industry contacts and our deep
              understanding of market dynamics, we negotiate and secure
              lucrative commercial rights deals that drive revenue and
              visibility for our clients. From broadcast rights to merchandising
              opportunities, MVP Quest Pvt Ltd ensures that every aspect of a
              sporting property’s commercial potential is fully realized,
              delivering tangible value to stakeholders and sponsors
            </h4>
           {/*- <button className="crh-button" data-aos="flip-up">
              View Report <FaArrowRight />
            </button>  -*/}
          </div>
        </div>
      </section>
      <section id="ss" className="ss-section">
        <div className="ss-para-div">
          <h1 className="ss-text text-center" data-aos="fade-down">
            "MVP Quest Pvt Ltd is a proven leader in selling sponsorships and
            forging partnerships that elevate the profile of sporting events and
            properties."
          </h1>
          <div className="ss-text-div">
            <h4 className="ss-subtext" data-aos="fade-down">
              "Through strategic planning, targeted outreach, and innovative
              activation strategies, we connect brands with unique opportunities
              to engage with fans and align their values with the excitement of
              live sports. Whether it’s securing title sponsorships, signage
              rights, or digital advertising opportunities, MVP Quest Pvt Ltd
              delivers results that exceed expectations and drive meaningful
              return on investment for sponsors."
            </h4>
            {/*- 
            <button className="ss-button" data-aos="flip-up">
              View Report <FaArrowRight />
            </button>
            -*/}
          </div>
        </div>

        <div className="s-container" data-aos="zoom-in">
          <div className="slider">
            <div className="slider-items">
              <img src="l1.png" alt />
              <img src="l2.png" alt />
              <img src="l32.png" alt />
              <img src="l4.png" alt />
              <img src="l5.png" alt />
              <img src="l6.png" alt />
              <img src="l7.png" alt />
              <img src="l8.png" alt />
              <img src="l9.png" alt />
              <img src="l10.png" alt />
              <img src="l11.png" alt />
              <img src="l12.png" alt />
            </div>
          </div>
        </div>
      </section>
      <section id="pms" className="pms-section">
        <div className="pms-para-div">
          <h1 className="pms-text " data-aos="fade-down">
            "At the heart of every successful sporting endeavor are the athletes
            who dedicate themselves to their craft. MVP Quest Pvt Ltd is
            committed to providing comprehensive player management services that
            support athletes in achieving their full potential both on and off
            the field."
          </h1>
          <div className="pms-text-div">
            <h4 className="pms-subtext" data-aos="fade-down">
              "From contract negotiations and career guidance to personal
              branding and marketing support, we empower athletes to focus on
              what they do best while we handle the rest. With a personalized
              approach and a commitment to excellence, MVP Quest Pvt Ltd is the
              partner of choice for athletes seeking to reach new heights in
              their careers."
            </h4>
           {/*-
            <button className="pms-button" data-aos="flip-up">
              View Report <FaArrowRight />
            </button>
            -*/} 
          </div>
        </div>
        <div className="two-page-section-main">
          <div className="two-page-section">
            <div className="left-two-page">
              <h1 className="left-two-page-head" data-aos="fade-right">
                {contentArray[currentIndex].heading}
              </h1>
             {/*- <p className="left-two-page-text" data-aos="fade-right">
                {contentArray[currentIndex].paragraph}
              </p>
              -*/}
              <div className="button-both">
                <button
                  className="left-arrow"
                  data-aos="flip-up"
                  onClick={handleLeftButtonClick}
                >
                  <FaArrowLeft />
                </button>
                <button
                  className="right-arrow"
                  data-aos="flip-down"
                  onClick={handleRightButtonClick}
                >
                  <FaArrowRight />
                </button>
              </div>
            </div>
            <div className="right-two-page">
              <img
                className="image-two-page"
                data-aos="fade-left"
                src={contentArray[currentIndex].imageUrl}
                alt="Right Page Image"
              />
            </div>
          </div>
        </div>
        <div className="text-two-img">
          <h2></h2>
        </div>
      </section>
      <section id="gallery" className="gallery-section">
        <div className="gallery-main">
          <div className="gallery-left">
            <h3 className="gallery-left-head" data-aos="fade-right">
              Celebrating Sporting Excellence with{" "}
              <span className="gallery-left-head-span">MVP</span>
            </h3>
            <p className="gallery-left-para" data-aos="fade-right">
              Explore the essence of sporting excellence with MVP Quest
              Management Pvt. Ltd. Our gallery captures thrilling moments,
              inspiring athletes, and unforgettable events that define our
              commitment to sports. From exhilarating matches to
              behind-the-scenes glimpses, join us in celebrating the spirit of
              sportsmanship and the pursuit of greatness.
            </p>
          </div>
          <div className="gallery-right">
          <article className="gallery_wrapper" data-aos="zoom-in">
            <img
              src="https://img.freepik.com/free-photo/water-polo-trophy-by-pool-with-ball_23-2150893973.jpg?t=st=1715678290~exp=1715681890~hmac=13268584b9272c49c94652cc095cfde77ba746db89bd11ca126a7a1a3f69b794&w=900"
              alt="Balloon with controled fire"
              className="gallery-img"
            />
            <img
              src="https://img.freepik.com/free-photo/water-polo-players-pool-with-swimming-equipment_23-2150893933.jpg?t=st=1715678335~exp=1715681935~hmac=f830a437ea6a9652375a9d09361ba85ca978f555f75b4a436ad9941d4953d436&w=900"
              alt="Minimalists catchphrase"
              className="gallery-img"
            />
            <img
              src="https://img.freepik.com/free-photo/front-view-male-rugby-players-with-ball_23-2148793311.jpg?t=st=1715678393~exp=1715681993~hmac=fc800840c50e76589f6d6bdfe75676434b2f4de2a681924ee18a548706ad24bc&w=900"
              alt="Hiking Directional Signs"
              className="gallery-img"
            />
            <img
              src="https://img.freepik.com/free-photo/field-hockey-players-tournament-game_23-2149668538.jpg?t=st=1715678464~exp=1715682064~hmac=f262ab8bb643d322b4148496e9d8599ee902432ffbfaec66b6d24b7714c82e11&w=900"
              alt="drinks"
              className="gallery-img"
            />
            <img
              src="https://img.freepik.com/free-photo/field-hockey-players-tournament-game_23-2149668594.jpg?t=st=1715678578~exp=1715682178~hmac=eed5396736825d2d6181f74aae4ba98fe1e982e38216d4c677f3f79e14ef51d8&w=900"
              alt="breakfast"
              className="gallery-img"
            />
            <img
              src="https://img.freepik.com/premium-photo/football-game-stadium_207634-2790.jpg?w=900"
              alt="Alpine mountains under a clear sky"
              className="gallery-img"
            />
            <img
              src="https://img.freepik.com/free-photo/athlete-starting-line-stadium_23-2149399602.jpg?t=st=1715678674~exp=1715682274~hmac=9eaa2a7dcec3f84ea82219df61b78c43a2bec654983c382afb71b465bd355bda&w=900"
              alt="Gradient Glowing Laptop"
              className="gallery-img"
            />
            <img
              src="https://img.freepik.com/free-photo/high-angle-waterpolo-players_23-2151202979.jpg?t=st=1715678703~exp=1715682303~hmac=4510719b009fdfe872ee6f9840ac2b2ceadc3f3d736d7d96364bc639a8eead41&w=900"
              alt="Staples container"
              className="gallery-img"
            />
            <img
              src="https://img.freepik.com/free-photo/portrait-young-man-football-playing-motion-training-isolated-black-studio-background-kicking-ball_155003-46281.jpg?t=st=1715678775~exp=1715682375~hmac=81938baf56830d5633c2b1d8e075fad6ba0f9ac763d10911bbcbf65fccee7d39&w=900"
              alt="the island"
              className="gallery-img"
            />
            <img
              src="https://img.freepik.com/free-photo/baseball-players-professional-baseball-stadium-evening-game_654080-1532.jpg?t=st=1715678806~exp=1715682406~hmac=bd37a3690b6eea40f2eacea12d3f212b7fff749173dafd99fce5ea45210dd1e5&w=900"
              alt="Crystal Tower"
              className="gallery-img"
            />
            <img
              src="https://img.freepik.com/free-photo/professional-basketball-player-action-basketball-field_613910-21493.jpg?t=st=1715678847~exp=1715682447~hmac=63ce878bf66690751043351df28f8e365b3ba2229c8f65cfd0fc6310f4be9d5e&w=740"
              alt="Car with man on death valley"
              className="gallery-img"
            />
            <img
              src="https://img.freepik.com/premium-photo/professional-tennis-player-stadium-mixed-media_641298-6826.jpg?w=900"
              alt="christmas elf looking dog"
              className="gallery-img"
            />
            <img
              src="https://img.freepik.com/free-photo/motocross-rider-action-motocross-sport-moto-sport_654080-167.jpg?t=st=1715678918~exp=1715682518~hmac=88f1dd4773878db7bfc0a7d1d011bf0de33effddfedbae2615df784d2b1f3382&w=900"
              alt="interior design"
              className="gallery-img"
            />
          </article>
          </div>
        </div>
      </section>
      <section id="news" className="news-section-main">
        <div className="news-section">
          <div className="first-news">
            <div className="news-head-div">
              <h2 className="news-head-div-text">
                Discover our newest headlines
              </h2>
            </div>
          </div>
          <div className="second-news">
            <div className="left-right-news">
              <div className="card-news" data-aos="fade-down">
                <img src="n1.jpg" alt="News Image" />
                <span>/ 14.01.2024</span>
                <h4>Starting news update</h4>
              </div>
              <div className="card-news" data-aos="fade-down">
                <img src="n2.jpeg" alt="News Image" />
                <span>/ 05.02.2024</span>
                <h4>
                  MVP’s Sport 24 Announces Deal To Broadcast UEFA Euro 2024
                </h4>
              </div>
              <div className="card-news" data-aos="fade-down">
                <img src="n3.jpg" alt="News Image" />
                <span>/ 2.01.2024</span>
                <h4>Behind the deal: The NWSL & IMG</h4>
              </div>
             {/*- <button className="news-button">
                All MVP News <FaArrowRight />
              </button> -*/} 
            </div>
            <div className="right-right-news">
              <div className="card-news" data-aos="fade-up">
                <img src="n4.jpg" alt="News Image" />
                <span>/ 12.02.2024</span>
                <h4>Leaf Trading Cards Becomes Official Trading Card of PBR</h4>
              </div>
              <div className="card-news" data-aos="fade-up">
                <img src="n5.jpg" alt="News Image" />
                <span>/ 23.04.2024</span>
                <h4>
                  Endeavor Launches ‘NXT UK: Women in Sports’ Programme to
                  Increase Access to Sports Industry for Women in the U.K.
                </h4>
              </div>
              <div className="card-news" data-aos="fade-up">
                <img src="n6.jpg" alt="News Image" />
                <span>/ 19.04.2024</span>
                <h4>Laura Ashley announces fashion relaunch</h4>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="cu" className="conatctus-section">
        <div class="contact_us">
          <div class="container-contact">
            <div class="row-contact">
              <div class="col-md-10 offset-md-1">
                <div class="contact_inner">
                  <div class="row">
                    <div class="col-md-10">
                      <div class="contact_form_inner">
                        <div class="contact_field">
                          <h3>Contact Us</h3>
                          <p>
                            Feel Free to contact us any time. We will get back
                            to you as soon as we can!.
                          </p>
                          <form className="contact-form" onSubmit={handleSubmit}>
      <label htmlFor="name">Name:</label>
      <input
        type="text"
        id="name"
        name="name"
        value={formData.name}
        onChange={handleChange}
        required
      />
      {errors.name && <span className="error">{errors.name}</span>}

      <label htmlFor="email">Email:</label>
      <input
        type="email"
        id="email"
        name="email"
        value={formData.email}
        onChange={handleChange}
        required
      />
      {errors.email && <span className="error">{errors.email}</span>}

      <label htmlFor="mobile">Mobile:</label>
      <input
        type="tel"
        id="mobile"
        name="mobile"
        value={formData.mobile}
        onChange={handleChange}
        required
      />
      {errors.mobile && <span className="error">{errors.mobile}</span>}

      <label htmlFor="message">Message:</label>
      <textarea
        id="message"
        name="message"
        value={formData.message}
        onChange={handleChange}
        required
      ></textarea>
      {errors.message && <span className="error">{errors.message}</span>}

      <button type="submit">Send</button>
    </form>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="right_conatct_social_icon d-flex align-items-end"></div>
                    </div>
                  </div>
                  <div class="contact_info_sec">
                    <h4>Contact Info</h4>
                    <div class="d-flex info_single align-items-center">
                      <i class="fas fa-headset"></i>
                     {/*-<span>+91 0000 0000 00</span>-*/} 
                    </div>
                    <div class="d-flex info_single align-items-center">
                      <i class="fas fa-envelope-open-text"></i>
                      <span>info@themvpquest.com</span>
                    </div>
                    <div class="d-flex info_single align-items-center">
                      <i class="fas fa-map-marked-alt"></i>
                      {/*- <span>
                        1000+ Travel partners and 65+ Service city Noida across
                        India
                      </span>-*/}
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      
    </>
  );
};

export default Home;
