
import './App.css';
import AOS from "aos";
import "aos/dist/aos.css";
import Home from './Components/Home/Home';
import Footer from './Components/Footer/Footer';
AOS.init({
  duration: 1000,
});

function App() {
  return (
    <>
<Home/>
<Footer/>
    </>
  );
}

export default App;
